$base-color: #121316;
$main-color: #3992ff;
$main-text-color: #ffffff;
$secondary-text: #a3a3a3;
@import "./breakpoints.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

//----------------------------------------- general

* {
    font-family: "Poppins", sans-serif;
    color: white;
    padding: 0;
    background-color: $base-color;
    margin: 0;
    box-sizing: border-box;
}

//----------------------------------------- layout

main {
    min-height: 100vh;
}
.page {
    max-width: 150ch;
    margin: 0 7%;
    @include media(">2xl") {
        margin: 0 auto;
    }
}
nav {
    padding: 1.5em 0;
    background-color: black;
    color: white;
    margin-bottom: 1em;
    position: sticky;
    top: 0;
    z-index: 1;
    .navContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: black;
        max-width: 150ch;
        margin: 0 7%;
        @include media(">2xl") {
            margin: 0 auto;
        }
    }
    .logo {
        height: 2rem;
        background-color: black;
    }
    .navText {
        color: $secondary-text;
        background-color: black;
        span {
            color: $secondary-text;
            @include media("<sm") {
                color: $secondary-text;
                font-size: 0.7rem;
            }
        }
    }
}
footer {
    text-align: center;
    color: $secondary-text;
    margin-top: auto;
    padding: 2em 0;
    position: sticky;
    top: 100%;
}

//----------------------------------------- Recruitment

.recruitmentContainer {
    margin: 2em 0;
    max-width: 150ch;
    .steps {
        flex-basis: 90%;
        // flex-grow: 1;
    }
    @include media(">xl") {
        margin: 0 auto;
    }
    @include media(">md") {
        display: flex;
        gap: 2em;
    }
}
form {
    width: 100%;
    flex-shrink: 1;
    flex-grow: 1;
}
.formTitle {
    margin-bottom: 2em;
    h1 {
        font-weight: 400;
    }
    div {
        color: $secondary-text;
        font-weight: 300;
    }
}

.formContanier {
    display: flex;
    gap: 2em;
    flex-wrap: wrap;
}

.stepsIndication {
    color: $secondary-text;
    font-weight: 300;
}

.stepTitle {
    color: white;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-bottom: 1.5em;
    font-weight: 400;
}
.fieldContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.2em;
}
.fieldLabel::after {
    content: " *";
    color: red;
    font-weight: bold;
}
label.optional::after {
    content: "";
}

// ------------------------------------- text field, text box and drop down items styling
.inputField {
    border: #a3a3a3 0.1em solid;
    border-radius: 0.4em;
    outline: none;
    background-color: transparent;
    box-sizing: border-box;
    padding: 0.3em 0.4em;
    resize: vertical;
}
.inputField:focus,
.inputField:hover {
    border: #3992ff 0.1em solid;
}
.dropDownItems {
    color: white;
    background-color: #121316;
}

// ------------------------------------- main stepper Styling

.mainStepperButtonsContainer {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1.5em;
    margin-top: 1em;
}
.primaryButton {
    background-color: #3992ff;
    color: white;
    border-radius: 4em;
    padding: 0.6em 2em;
    cursor: pointer;
    border: none;
}
.secondaryButton {
    color: #a3a3a3;
    cursor: pointer;
    background-color: transparent;
    border: none;
}

// ------------------------------------- small special stepper Styling

.smallSpecialStepperContainer {
    position: sticky;
    top: 5em;
    background-color: $base-color;
    overflow-x: scroll;
    scrollbar-width: none;
    padding: 1em 0;
    @include media(">=md") {
        display: none;
    }
}
.smallSpecialStepperButtonsContainer {
    display: flex;
    gap: 0.5em;
    min-width: 35em;
}
.smallSpecialStepperContainer::-webkit-scrollbar {
    display: none;
}
.smallSpecialStepperButton {
    border: 0;
    border-radius: 2em;
    padding: 0.3em;
    flex: 1 1 0;
}
.smallSpecialStepperActiveButton {
    background-color: $main-color;
}
.smallSpecialStepperInactiveButton {
    background-color: $secondary-text;
}
.smallSpecialStepperDisapledButton {
    background-color: #363636;
    cursor: not-allowed;
}

// ------------------------------------- big special stepper Styling
.bigSpecialStepperConatiner {
    position: sticky;
    top: 6.5em;
    height: fit-content;
    @include media("<md") {
        display: none;
    }
}
.bigSpecialStepperButtonContainer {
    display: flex;
    align-items: center;
    gap: 0.9em;
    margin-bottom: 0.9em;
}
.bigSpecialStepperIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 3em;
    height: 3em;
    border: solid 0.1em $secondary-text;
    border-radius: 100%;
}
.bigSpecialStepperActiveIconContainer {
    border: solid 0.1em $main-color;
}
.bigSpecialStepperDisabledIconContainer {
    border: solid 0.1em #363636;
    cursor: not-allowed;
}
.bigSpecialStepperButtonLabel {
    max-width: 10em;
}
.bigSpecialStepperDisapbledButtonLabel {
    color: #363636;
    cursor: not-allowed;
}
.bigSpecialStepperInactiveButtonLabel {
    color: $secondary-text;
}
.hello {
    background-color: red;
}

.bigSpecialStepperActiveIcon {
    color: $main-color;
}
.bigSpecialStepperDisapledIcon {
    color: #363636;
}

// ------------------------------------- text field, text box and drop down items styling

.inputField {
    border-radius: 0.4em;
    outline: none;
    background-color: transparent;
    box-sizing: border-box;
    padding: 0.3em 0.4em;
}
.invalidInputField {
    border: red 0.1em solid;
    border-radius: 0.4em;
    outline: none;
    background-color: transparent;
    box-sizing: border-box;
    padding: 0.3em 0.4em;
}
.inputField:focus,
.inputField:hover {
    border: #3992ff 0.1em solid;
}
.dropDownItems {
    color: white;
    background-color: #121316;
}

// ------------------------------------- radio button styling
.radioSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.2em;
    margin-top: 0.5em;
}
.radioItemInput {
    display: none;
    position: relative;
}
.radioItemInput:checked ~ .radioItemLabel::before {
    outline: #3992ff 0.1em solid;
    background-color: #3992ff;
}
.radioItemInput:checked + .radioItemLabel::after {
    background-color: #3992ff;
    outline: border;
    display: inline;
}
.radioItemLabel {
    position: relative;
    padding-left: 1.3em;
    cursor: pointer;
}
.radioItemLabel::before {
    content: "";
    width: 0.6em;
    height: 0.6em;
    position: absolute;
    left: -0.02em;
    top: 0.15em;
    background-color: transparent;
    border: #121316 0.2em solid;
    outline: #a3a3a3 0.1em solid;
    border-radius: 50%;
}
.radioItemLabel:hover::before {
    outline: #3992ff 0.1em solid;
}

// ------------------------------------- special range styling
.specialRangeContainer {
    display: flex;
    gap: 0.2em;
    margin: 0.5em 0 1em 0;
}
.specialRangeItem {
    display: flex;
    width: 100%;
    cursor: pointer;
}
.specialRangeInput {
    display: none;
}
.specialRangeInput:checked + .specialRangeLabel {
    background-color: #3992ff;
}
.specialRangeInput:hover + .specialRangeLabel {
    background-color: #3992ff;
}
.specialRangeLabel {
    background-color: #a3a3a3;
    text-align: center;
    flex-grow: 1;
}

// ------------------------------------------ validation messages

.validationErrorMessage {
    padding: 0.5em 0.5em;
    margin: 1em 0;
    background-color: rgb(64, 20, 20);
    color: #fbbebe;
    border-radius: 0.5em;
}
.errorMsgIcon {
    background-color: rgb(64, 20, 20);
    margin-right: 0.7em;
}

//-------------------------------------------

.twoInputColumns {
    display: flex;
    flex-direction: row;
    gap: 3em;
    @include media("<=lg") {
        display: block;
    }
    .column {
        min-width: 5em;
        flex-grow: 1;
        flex-basis: 50%;
        @include media(">md") {
            flex-shrink: 1;
        }
    }
}
//--------------------------------- popup
.popupContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: #121316b2;
    inset: 0;
    z-index: 1;
}
.popup {
    position: fixed;
    background-color: white;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1em;
    padding: 1em 2em;
    border-radius: 1em;
    span {
        color: black;
        background-color: #fff;
    }
}

.popupButtonsContainer {
    display: flex;
    background-color: #fff;
    gap: 1em;
}

//--------------------------------------------------------- alerts
.messageContainer {
    position: fixed;
    top: 2em;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}
.message {
    padding: 1em 1em;
    background-color: white;
    min-width: 20em;
    border-radius: 0.7em;
    @include media(">md") {
        width: 35em;
    }
    .messageIcon {
        background-color: #fff;
        margin-right: 0.7em;
    }
    .messageContent {
        color: black;
        background-color: #fff;
        margin-bottom: 1em;
    }
    .buttonConatiner {
        background-color: #fff;
        display: flex;
        justify-content: end;
        align-items: right;
    }
}

//-----------
